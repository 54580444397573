import { Injectable } from '@angular/core';
import { ComponentsService, PlaylistComponentInfo, PlaylistComponentItem } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { MediaSelectorService } from './media-selector.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { environment } from 'src/environments/environment';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { WebPageService } from './web-page.service';

export type BasePlaylistTemplateItem = {
  id?: string;
  label?: string;
  tagName: string;
};

@Injectable({
  providedIn: 'root'
})
export class BasePlaylistService {
  public static readonly FOLDER_TYPE = { GOOGLE_PHOTOS_ALBUM: 'google-photos-album' };

  playlistComponents: PlaylistComponentItem[] = ComponentsService.PLAYLIST_COMPONENTS;
  playlistItems: any[] = [];
  componentId: string;

  constructor(
    protected componentsService: ComponentsService,
    protected mediaSelectorService: MediaSelectorService,
    protected templateEditorService: TemplateEditorService,
    protected webPageService: WebPageService,
    protected currentPlanService: CurrentPlanService,
    protected attributeDataService: AttributeDataService,
    protected analyticsFactory: AnalyticsFactory,
    protected storageManagerService: StorageManagerService,
    protected companyStateService: CompanyStateService
  ) { }

  protected getComponentObject(item: BasePlaylistTemplateItem): PlaylistComponentInfo {
    return {
      type: item.tagName,
      label: item.label,
      id: this.componentId + ' ' + this.playlistItems.indexOf(item)
    };
  }

  protected safeParseJson(string: string): any {
    try {
      return JSON.parse(string);
    } catch (err) {
      console.error(err, string);
      return null;
    }
  }

  protected getGooglePhotosItemAttributes() {
    return {
      type: BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM,
      environment: environment.production ? 'prod' : 'test'
    };
  }

  addItem(type: string, options?: any): void {}

  getComponentName(item: BasePlaylistTemplateItem) {
    return this.componentsService.getComponentName(this.getComponentObject(item));
  }

  getComponentIcon(item: BasePlaylistTemplateItem) {
    return this.componentsService.getComponentIcon(this.getComponentObject(item));
  }

  getComponentByType(type: string): PlaylistComponentItem {
    return ComponentsService.COMPONENTS_ARRAY.find((item) => {
      return item.type === type;
    }) || {} as PlaylistComponentItem;
  }

  editComponent(item: BasePlaylistTemplateItem, playlist: boolean = false) {
    this.componentsService.editComponent(this.getComponentObject(item), { playlist });
  }

  reset() {
    this.componentId = '';
    this.playlistItems = [];
    this.playlistComponents = ComponentsService.PLAYLIST_COMPONENTS;
  }

  showMediaSelector(options?: any) {
    this.mediaSelectorService.onMediaUpdate = null;
    this.mediaSelectorService.onUserComponentAdd = null;
    this.mediaSelectorService.editingCanva = false;

    const fileType = (options && 'fileType' in options) ? options.fileType : null;
    const singleFile = (options && 'singleFile' in options) ? options.singleFile : false;
    const stockLibrary = (options && 'stockLibrary' in options) ? options.stockLibrary : false;

    this.componentsService.editComponent({
      type: 'rise-media-selector'
    }, {
      fileType,
      singleFile,
      stockLibrary
    });
  }

  showCanva(source?) {
    if (!this.currentPlanService.isPlanActive()) {
      this.currentPlanService.showUnlockThisFeatureModal();
      return;
    }

    this.mediaSelectorService.onMediaUpdate = null;
    this.mediaSelectorService.onUserComponentAdd = null;
    this.mediaSelectorService.editingCanva = !!source;

    this.componentsService.editComponent({
      type: 'rise-canva',
    }, {
      source
    });
  }
}
