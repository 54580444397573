import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import * as _ from 'lodash';

import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { PlansService } from './plans.service';

import { UnlockFeatureComponent } from './unlock-feature/unlock-feature.component';
import { StateService } from '@uirouter/angular';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentPlanService {

  currentPlan: any;

  constructor(private broadcaster: BroadcasterService,
    private stateService: StateService,
    private modalService: ModalService,
    private companyStateService: CompanyStateService,
    private plansService: PlansService,
    private analyticsFactory: AnalyticsFactory) {

    this._loadCurrentPlan();

    broadcaster.on('risevision.company.selectedCompanyChanged', () => {
      this._loadCurrentPlan();
    });

    broadcaster.on('risevision.company.updated', () => {
      this._loadCurrentPlan();
    });
  }

  _loadCurrentPlan() {
    var company = this.companyStateService.getCopyOfSelectedCompany();
    var plan = null;

    if (company.id && company.parentPlanProductCode) {
      plan = _.cloneDeep(this.plansService.getPlan(company.parentPlanProductCode));
      plan.isParentPlan = true;
      plan.status = 'Active';

    } else if (company.id && company.planProductCode) {
      plan = _.cloneDeep(this.plansService.getPlan(company.planProductCode));

      plan.status = company.planSubscriptionStatus;
      plan.trialPeriod = company.planTrialPeriod;
      plan.currentPeriodEndDate = new Date(company.planCurrentPeriodEndDate);
      plan.trialExpiryDate = new Date(company.planTrialExpiryDate);

    } else {
      plan = _.cloneDeep(this.plansService.getFreePlan());
    }

    this.currentPlan = plan;

    plan.subscriptionId = company.planSubscriptionId;
    plan.playerProTotalLicenseCount = company.playerProTotalLicenseCount;
    plan.playerProAvailableLicenseCount = company.playerProAvailableLicenseCount;

    plan.shareCompanyPlan = company.shareCompanyPlan;

    plan.billToId = company.planBillToId;
    plan.isPurchasedByParent = !!company.planBillToId && !!company.planShipToId && (company.planBillToId !==
      company.planShipToId) && (this.isSubscribed() || this.isCancelledActive());
    plan.isAllocatedByParent = !!company.licenseAllocationActive && company.allocatedFromCompanyId !== company.id;
    plan.parentPlanCompanyName = company.parentPlanCompanyName;
    plan.parentPlanContactEmail = company.parentPlanContactEmail;

    console.debug('Current plan', plan);
    this.broadcaster.emitWithParams('risevision.plan.loaded', plan);
  }

  isPlanActive () {
    return this.isSubscribed() || this.isOnTrial() || this.isCancelledActive();
  }

  isFree () {
    return this.currentPlan.type === 'free';
  }

  get canModifyPlan() : boolean {
    return !(this.currentPlan.isParentPlan ||
      this.currentPlan.isPurchasedByParent ||
      this.currentPlan.isAllocatedByParent);
  }

  isParentPlan () {
    return !!this.currentPlan.isParentPlan;
  }

  isEnterpriseSubCompany () {
    return this.currentPlan.type === 'enterprisesub';
  }

  isSubscribed () {
    return !this.isFree() && this.currentPlan.status === 'Active';
  }

  isOnTrial () {
    return !this.isFree() && this.currentPlan.status === 'Trial';
  }

  isTrialExpired () {
    return !this.isFree() && this.currentPlan.status === 'Trial Expired';
  }

  isSuspended () {
    return !this.isFree() && this.currentPlan.status === 'Suspended';
  }

  isCancelled () {
    return !this.isFree() && this.currentPlan.status === 'Cancelled';
  }

  isCancelledActive () {
    var now = new Date();

    return this.isCancelled() && (this.currentPlan.currentPeriodEndDate > now);
  }

  isUnlimitedPlan() {
    return this.plansService.isUnlimitedPlan(this.currentPlan);
  }

  // old plans factory
  showPurchaseOptions (displayCount?) {
    this.stateService.go('apps.purchase.home', {
      displayCount: displayCount,
      subscriptionId: ''
    });
  }

  confirmAndPurchase (displayCount?) {
    if (!this.canModifyPlan) {
      var contactInfo = this.currentPlan.parentPlanContactEmail ? ' at ' +
        this.currentPlan.parentPlanContactEmail : '';
      this.modalService.showMessage('Almost there!',
        'There aren\'t available display licenses to assign to the selected displays. Contact your account administrator' +
        contactInfo + ' for additional display licenses.');
    } else {
      this.modalService.confirm('Almost there!',
          'There aren\'t available display licenses to assign to the selected displays. Subscribe to additional licenses?',
          'Yes', 'No')
        .then(() => {
          this.showPurchaseOptions(displayCount);
        });
    }
  }

  showUnlockThisFeatureModal () {
    this.analyticsFactory.track('free user popup seen', {
      source: 'share schedule button'
    });
    this.modalService.showModal(UnlockFeatureComponent, {
      class: 'madero-style modal-sm'
    }).then(() => {
      this.showPurchaseOptions();
    });
  }

  initPlanTrial () {
    var plan = this.plansService.getUnlimitedPlan();

    var licenses = plan.proLicenseCount;
    var selectedCompany = this.companyStateService.getCopyOfSelectedCompany(true);
    var trialExpiry = new Date();
    trialExpiry.setDate(trialExpiry.getDate() + plan.trialPeriod);
    // Round down the date otherwise the subtraction may calculate an extra day
    trialExpiry.setHours(trialExpiry.getHours() - 1);

    selectedCompany.planProductCode = plan.productCode;
    selectedCompany.planTrialPeriod = plan.trialPeriod;
    selectedCompany.planTrialExpiryDate = trialExpiry;
    selectedCompany.planSubscriptionStatus = 'Trial';
    selectedCompany.playerProTotalLicenseCount = licenses;
    selectedCompany.playerProAvailableLicenseCount = licenses;

    this.companyStateService.updateCompanySettings(selectedCompany);
  }

}

angular.module('risevision.common.components')
  .factory('currentPlanService', downgradeInjectable(CurrentPlanService));
