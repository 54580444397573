import { ChangeDetectorRef, ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, scan, takeUntil } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { environment } from 'src/environments/environment';

export type SignalServerData = {
  type: string;
  id?: string;
  channelId?: string;
  iceConfiguration?: {
    iceServers: { urls: string }[]
  };
  candidate?: {
    candidate: string;
    sdpMLineIndex: number;
    sdpMid: string;
  };
  sdp?: string;
  channel?: any;
};

@Injectable({
  providedIn: 'root'
})
export class ScreenSharingService {
  socket: WebSocketSubject<SignalServerData>;
  peerConnection: RTCPeerConnection;
  channelError: string;
  iceConfiguration: any;
  connecting: boolean = false;
  preview: ElementRef;
  joinedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly maxReconnectAttempts = 5; // Adjust this as needed
  private readonly reconnectInterval = 5000; // Adjust this as needed (milliseconds)
  private destroy$ = new Subject<void>();

  constructor(private webSocketService: WebSocketService) { }

  join(channelId: string, changeDetector?: ChangeDetectorRef) {
    console.log('Joining channelId: ', channelId);
    this.channelError = '';

    this.connectToWebSocket(channelId, changeDetector)
      .pipe(
        takeUntil(this.destroy$),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => {
              console.log(`Retrying WebSocket connection in ${this.reconnectInterval}ms...`);
              return timer(this.reconnectInterval);
            }),
            scan((retryCount, err) => {
              if (retryCount >= this.maxReconnectAttempts) {
                throw err; // Throw error to stop retrying
              } else {
                console.log(`WebSocket reconnecting attempt ${retryCount + 1}`);
                return retryCount + 1;
              }
            }, 0), // Initial retry count
            catchError(err => {
              console.error(`WebSocket retry attempts exhausted (${this.maxReconnectAttempts}).`);
              throw err; // Rethrow the error to propagate it to the subscription's error handler
            })
          )
        )
      )
      .subscribe({
        next: (data) => {
          console.log('streamer onmessage', data);

          switch (data.type) {
            case 'joined-channel':
              console.log("Joined a new channel", data);
              this.iceConfiguration = data.iceConfiguration;
              this.connecting = true;
              this.start(changeDetector);
              break;

            case 'invalid-channel':
              console.warn('Invalid Channel: ', data);
              this.channelError = 'Invalid Channel Id: ' + channelId + '.';
              this.reset();
              break;

            case 'icecandidate':
              this.peerConnection?.addIceCandidate(data.candidate);
              break;

            case 'answer':
              console.log('Received answer');
              this.peerConnection?.setRemoteDescription({
                type: data.type,
                sdp: data.sdp ?? ""
              });
              break;

            case 'new-member':
              let streaming = !!this.peerConnection;
              console.log('New member joined', data, 'streaming', streaming);
              if (streaming) {
                console.log('Restarting stream');
                this.closePeerConnection();
                this.stream();
              }
              break;

            default:
              console.log('Unknown message', data);
          }
        },
        error: (e) => {
          console.log('WebSocket error:', e);
          this.reset();
        }
      });
  }

  private connectToWebSocket(channelId: string, changeDetector?: ChangeDetectorRef): Observable<SignalServerData> {
    return new Observable<SignalServerData>(observer => {
      this.socket = this.webSocketService.webSocket(`${environment.SCREEN_SHARING_SERVICE_URL}?channelId=stream-video-${channelId}`);
      this.socket.subscribe(
        data => observer.next(data),
        err => observer.error(err),
        () => observer.complete()
      );
    });
  }

  start(changeDetector?: ChangeDetectorRef) {
    navigator.mediaDevices.getDisplayMedia({ video: true })
      .then((stream) => {
        this.joinedSubject.next(true);
        if (changeDetector) {
          changeDetector.detectChanges();
        }

        this.preview.nativeElement.srcObject = stream;

        this.preview.nativeElement.srcObject.getTracks()
          .forEach(track => {
            track.addEventListener("ended", (e) => {
              console.log("track removed", e);

              this.socket.next({ type: 'client-stream-closed' });

              this.joinedSubject.next(false);
              this.closePeerConnection();
            });
          });
        this.stream();
      })
      .catch(e => {
        console.log('getDisplayMedia error: ', e);
      })
      .finally(() => {
        this.connecting = false;
      });
  }

  stream() {
    this.peerConnection = new RTCPeerConnection(this.iceConfiguration);
    this.peerConnection.addEventListener(
      "connectionstatechange",
      () => {
        console.log('peerConnection.connectionstatechange', this.peerConnection.connectionState);

        switch (this.peerConnection.connectionState) {
          case "new":
          case "connecting":
          case "connected":
            break;
          case "disconnected":
          case "closed":
          case "failed":
            this.closePeerConnection();
            break;
          default:
            break;
        }
      },
      false,
    );

    this.peerConnection.addEventListener('icecandidate', e => {
      let candidate = null;
      if (e.candidate !== null) {
        candidate = {
          candidate: e.candidate.candidate,
          sdpMid: e.candidate.sdpMid,
          sdpMLineIndex: e.candidate.sdpMLineIndex,
        };
      }
      this.socket.next({ type: 'icecandidate', candidate });
    });

    this.preview.nativeElement.srcObject.getTracks()
      .forEach(track => this.peerConnection.addTrack(track, this.preview.nativeElement.srcObject));

    this.peerConnection.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
      .then(async offer => {
        await this.peerConnection.setLocalDescription(offer);
        console.log('Created offer, sending...');
        this.socket.next({ type: 'offer', sdp: offer.sdp });
      });
  }

  reset() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.preview?.nativeElement.srcObject) {
      this.preview.nativeElement.srcObject.getTracks().forEach(track => track.stop());
      this.preview.nativeElement.srcObject = null;
    }

    this.closePeerConnection();
    this.socket.complete();
    this.joinedSubject.next(false);
  }

  resetChannel() {
    this.socket.next({ type: 'reset-channel', channel: this.socket });
  }

  closePeerConnection() {
    if (this.peerConnection) {
      try {
        this.peerConnection.close();
      } catch (e) {
        console.error('Error closing peerConnection', e);
      } finally {
        this.peerConnection = null;
      }
    }
  }
}
